export enum Operator {
  eq = '=',
  neq = '!=',
  lt = '<',
  gt = '>',
  lteq = '<=',
  gteq = '>=',
  in = 'IN',
  out = '!IN'
}
